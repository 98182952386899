body {
  padding-top: 56px;
  overflow: scroll;
  background-color: #f2faff;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

 a {
  color: #00f;
  text-decoration: underline;
  background-color: transparent;
  transition: all .5s;
}


.jumbotron.HomePage {
  background-image: url('./assets/industry-banner.jpg');
  color: #fff;
  background-size: cover;
  /* background-color: rgba(255, 255, 255, .3); */
  padding: 0;
}

@media only screen and (min-width: 600px) {
  .jumbotron.HomePage {
    padding: 1em;
  }
}

.jumbotron .jumbotronText {
  /* background-color:rgb(0 23 89 / 60%); */
  background-color:rgb(255, 255, 255, .8);
  color: #000;
  margin: auto;
  width: 100%;
  padding: 1em;
  border-radius: 0;
}

@media only screen and (min-width: 600px) {
  .jumbotron .jumbotronText {
    width: 90%;
    border-radius: 1em;
  }
}

#root .jumbotron.HomePage h1 {
  color: #000;
}


.cardShadow {
  box-shadow: none;
  overflow: hidden;
  transition: all .5s;
}

.cardShadow:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, .8);
}

.Wrapper {
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
  border-top-left-radius: 1em !important;
  border-top-right-radius: 1em !important;
}

.cardShadow .scaleImg {
  filter: brightness(80%);
  transition: all .5s;
}

.cardShadow:hover .scaleImg {
  filter: brightness(100%);
}

.textCenter {
  text-align: center;
}

.ant-drawer-content-wrapper {
  margin-top: 56px;
}

.ant-modal-header {
  padding-right: .8em !important;
  border-bottom: 2px solid !important;
}

#root .navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  border-radius: 0;
  transition: all .75s;
}

#root .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover, .dropdown-item:focus, .dropdown-item:hover {
  background-color:#6495ed!important;
  color: #000;
  border-radius: 10px;
}

.dropdown-item:focus, .dropdown-item:hover {
  border-radius: 0px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ant-card-body .ant-card-meta-description {
  color: rgba(0, 0, 0, .8);
}

.ant-card-cover img
{
  filter: sepia(50%);
  border-top-right-radius: 1em !important;
  border-top-left-radius: 1em !important;
}
.ant-card-cover img:hover
{
  filter: blur(0) grayscale(0);
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}

.flexRowWrapBetween
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.FancyHeader
{
    display: inline-block;
    width: 100%;
    margin: 0;
    background-image: url('/src/assets/industry-banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: rgba(0, 0, 0, .5);
    background-clip: text;
    -webkit-background-clip: text;
    text-align: center;
    font-family: serif;
    font-weight: bolder;
    text-decoration: underline;
}

.FancyHeader_Jumbotron
{
    display: inline-block;
    width: 100%;
    margin: 0;
    color: rgba(0, 0, 0, .5);
    text-align: center;
    font-family: serif;
    font-weight: bolder;

    /* background-color: rgb(255, 255, 255, .8);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: bolder;
    width: 100%;
    text-align: center; */
}

.logo-container {
	position: relative;
	
	&:before {
		content:  "";
		position: absolute;
		top:      calc( 100% - 2px );
		width:    100%;
		height:   4px;
		background-color: darkblue;
		transform-origin: center center;
		transform: scaleX( 0 );
		
		animation: line-animation 3s ease-in-out ;
	}
	
	h1#page-logo {
		/* font: bold 6rem 'Arial', sans-serif; */
		text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bolder;
		animation: clip-path-reveal-1 3s ease-in-out ;
	}
}

@keyframes line-animation {
	0% { transform: scaleX( 0 ); }
	15% { transform: scaleX( 0 ); }
	20%, 25% { transform: scaleX( 1 ); top: calc( 100% - 2px ); }
	50% { transform: scaleX( 1 ); top: 0px; }
	70% { transform: scaleX( 0.2 ); top: 0px; }
	80%, 100% { transform: scaleX( 0.2 ); top: 0px; }
}

@keyframes clip-path-reveal-1 {
	0%, 25% { clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%); }
	50% { clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
}

.introText
{
    font-size: 1.5em;
}
nav a, 
nav a:hover
{
text-decoration: none;
}
nav a.navbar-brand:hover
{
background-color: transparent;
}