.skillsList {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    list-style-type: none;
}

@media (min-width: 800px) {
    .skillsList {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }

}

.skillsList li {
    line-height: 2.5;
}

.skillsList li::before {
    content: '✓';
    padding-right: 0.5em;
    color: green;
    font-weight: 900;
}

.skills {
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0s;
    opacity: 0;
    font-size: 1.5em;
}

.detailImg {
    margin: .75em 2em 0 .75em;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    opacity: 0;
    width: 95%;
    border-radius: 2em;
}

.article {
    font-size: 1.5em;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadedImg {
    position: relative;
    display: inline-block;
    color: #f6f9fb;
    float: left;
    width: 33%;
}

.fadedImg:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-collapse: separate;
    box-shadow: inset 0 0 30px 30px;
}

.FancyHeader {
    display: inline-block;
    width: 100%;
    margin: 0;
    background-image: url('/src/assets/industry-3087393_1920.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: rgba(0, 0, 0, .5);
    background-clip: text;
    -webkit-background-clip: text;
    text-align: center;
    font-family: serif;
    font-weight: bolder;


}